import Vue from "vue";
import App from "./App.vue";
import router from "./app-js/router.js";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import rules from "./app-js/rules";
import messages from "./app-js/messages";
import wysiwyg from "vue-wysiwyg";
import _ from "lodash";

import axios_req from "./app-js/new-base-axios";
import urls from "./app-js/api-urls";
// Eventbus globally declare
export const eventBus = new Vue();

const eventBus1 = new Vue();
Vue.prototype.eventBus1 = eventBus1;
Vue.config.productionTip = false;
// Vue.prototype.$urls = urls;
Vue.prototype.$rules = rules;
Vue.prototype.$messages = messages;
Vue.prototype.$Axios = axios_req;
Vue.prototype.$apiUrl = urls;
Vue.prototype.$_ = _;
// export const EventBus = new Vue();
// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.use(wysiwyg, {}); // config is optional. more below

Vue.mixin({
  beforeRouteUpdate: function (to, from, next) {
    let title = to.meta.tab_title.toUpperCase();
    let permission_list = sessionStorage.getItem("tab_permissions") || [];

    // let has_access = permission_list.includes(title);
    // if (permission_list) {
    if (to.meta.requiresAuth && permission_list.includes(title)) {
      next();
    } else {
      next({ name: "login" });
    }
    // }
    // else{
    //   this.router.push({name: "login"})
    // }
    // if (to.name !== "Login" && !has_access) {
    //   next({ name: "login" });
    // } else {
    //   next();
    // }

    // if (to.meta.requiresAuth == true) {
    //   let permission_list = sessionStorage.getItem("tab_permissions") || [];
    //   if (permission_list.includes(to.meta.title)) {
    //     console.log(permission_list.includes(to.meta.title));
    //     next();
    //   } else {
    //     console.log("denied");
    //     alert("Access Denied");
    //     next(from);
    //   }
    // }
  },
  methods: {
    showSnackBar(color, time, text) {
      this.$store.dispatch("snackBar/Snackbar", {
        show: true,
        color: color,
        message: text,
        timeout: time,
      });
    },

    formate_date(value) {
      let date = value;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },

    /**
     * Change LOS loan status.
     * @param {Object} loan_status - Next loan status.
     */
    updateLosStep(loan_status) {
      // console.log("loan_status",loan_status);
      let formdata = {
        loan_status: loan_status,
      };
      let look_up_key = this.$route.params.id;

      const successHandler = () => {
        this.$store.dispatch("losDetails/setCount");
      };
      const failureHandler = () => {
        return false;
      };

      return this.$Axios("put", this.$apiUrl.UPDATE_LOS_LOAN_STATUS, {
        data: formdata,
        lookUpKey: look_up_key,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    hasEditPermission(value) {
      // console.log("hasEditPermission",value);
      let action_permissions = JSON.parse(sessionStorage.getItem("action_permissions"));
      if (action_permissions.includes(value)) {
        return true;
      } else {
        return false;
      }
    },
    hasSpecialPermission(value) {
      let special_permissions = sessionStorage.getItem("special_permissions");

      if (special_permissions.includes(value)) {
        return true;
      } else {
        return false;
      }
    },
    // sort two dates in ascending
    sortDateRange(daterange) {
      // console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      // console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.formateDateDmy(from);
      to = this.formateDateDmy(to);
      return `${from} to ${to}`;
    },
    formateDateDmy(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      let new_date = `${day}-${month}-${year}`;
      return new_date;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
