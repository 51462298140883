export default {
  namespaced: true,
  state: {
    page: 1,
    ledger_category: "Customer",
    ledger: "",
  },

  mutations: {
    SET_FILTER(state, { page, ledger_category, ledger }) {
      state.page = page;
      state.ledger_category = ledger_category;
      state.ledger = ledger;
    },
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
  },
};
