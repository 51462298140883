export default {
  namespaced: true,
  state: {
    page: 1,
    display_daterange: "",
    daterange: [],
    payment_status: "",
    payment_type: "",
    search: "",
    selected_tab: 0,
  },

  mutations: {
    SET_FILTER(
      state,
      { page, display_daterange, daterange, payment_type, payment_status, search, selected_tab }
    ) {
      state.page = page;
      state.payment_status = payment_status;
      state.payment_type = payment_type;
      state.display_daterange = display_daterange;
      state.daterange = daterange;
      state.search = search;
      state.selected_tab = selected_tab;
    },
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
  },
};
