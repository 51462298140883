import Vue from 'vue'
import Vuex from 'vuex'
import snackBar from './modules/Snackbar';
import deleteWarning from './modules/DeleteWarning';
import reasonModal from './modules/ReasonModal';
import createLead from './modules/Los/CreateLead'
import losDetails from './modules/Los/Details'
import loanHistory from './modules/Lms/loanHistory'
import noticeModal from './modules/NoticeManagement';
import losFilter from './modules/Los/Filters/index';
import ledgerFilter from './modules/Ledger/Filters/index';
import lmsFilter from './modules/Lms/Filters/index';
import disbursmentFilter from './modules/Disbursement/Filters/index';
import collectionFilter from './modules/Collection/Filters/index';
import loanSchemeFilters from './modules/LoanScheme/Filters/index';
import teamFilters from './modules/Team/Filters/index';
import drawer from './modules/drawer';
import paymentFilters from './modules/Payments/Filters/index';
import reportsFilters from './modules/Reports/Filters/index';
import agentFilters from './modules/Agent/index';
Vue.use(Vuex)


const state = {
  search_text: "",
  drawer_flag: true,
};

const getters = {
  getSearchText: (state) => {
    return state.search_text;
  },
  getDrawerFlag: (state) => {
    return state.drawer_flag;
  }
}

const mutations = {
  SET_SEARCH_TEXT: (state, value) => {
    state.search_text = value
  },
  SET_DRAWER_FLAG: (state) => {
    state.drawer_flag = !state.drawer_flag
  }
};

const actions = {
  setSearchText: ({ commit }, value) => {
    commit("SET_SEARCH_TEXT", value)
  },
  setDrawerFlag: ({commit}) => {
    commit("SET_DRAWER_FLAG");
  }
};


export default new Vuex.Store({

  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {
    snackBar,
    deleteWarning,
    reasonModal,
    createLead,
    loanHistory,
    noticeModal,
    losDetails,
    losFilter,
    lmsFilter,
    ledgerFilter,
    disbursmentFilter,
    collectionFilter,
    loanSchemeFilters,
    teamFilters,
    paymentFilters,
    drawer,
    reportsFilters,
    agentFilters
  }
})
