export default {
    namespaced: true,
    state: {
        deleteModal: {
            show: false,
            emit: "",
            ID: null,
            text: "",
            isActive: false,
        },
        showModal: {
            show: false,
            emit: "",
            ID: null,
            type: "",
            action: "",
        },
        confirmModal: {
            show: false,
            emit: "",
            type: "",
            ID: null,
        },
    },
    getters: {
        getDeleteModal: (state) => state.deleteModal,
        getShowModal: (state) => state.showModal.show,
        getConfirmModal: (state) => state.confirmModal.show,
    },
    mutations: {
        TOGGLE_CONFIRM(state, obj) {
            if (obj.show) {
                state.confirmModal.ID = obj.id ? obj.id : null;
                state.confirmModal.emit = obj.emit ? obj.emit : "";
                state.confirmModal.type = obj.type ? obj.type : "";
            }
            state.confirmModal.show = obj.show;
            state.deleteModal.isActive = obj.isActive;

        },
        TOGGLE_MODAL(state, obj) {
            // console.log(obj, "delete modal")

            if (obj.show) {
                // console.log("delete",obj.text)
                state.deleteModal.ID = obj.id ? obj.id : null;
                state.deleteModal.emit = obj.emit ? obj.emit : "";
                state.deleteModal.text = obj.text ? obj.text : "";
            }
            state.deleteModal.show = obj.show;
            state.deleteModal.isActive = obj.isActive;
        },
        SHOW_MODAL(state, obj) {
            if (obj.show) {
                state.showModal.ID = obj.id ? obj.id : null;
                state.showModal.emit = obj.emit ? obj.emit : "";
                // console.log("state.showModal.emit",state.showModal.emit)
                state.showModal.type = obj.type ? obj.type : "";
                // state.showModal.method = obj.method ? obj.method : "";

                state.showModal.color = obj.color ? obj.color : "";
                state.showModal.action = obj.action ? obj.action : "";
            }
            state.showModal.show = obj.show;
            state.deleteModal.isActive = obj.isActive;

        },
    },
    actions: {
        toggleModal({ commit }, obj) {
            commit("TOGGLE_MODAL", obj);
        },
        showModal({ commit }, obj) {
            commit("SHOW_MODAL", obj);
        },
        toggleConfirm({ commit }, obj) {
            commit("TOGGLE_CONFIRM", obj);
        },
    },
};
