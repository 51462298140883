<template>
  <v-app>
    <SnackBar />
    <router-view />
  </v-app>
</template>

<script>
import SnackBar from "./components/SnackBar/SnackBar.vue";

export default {
  components: { SnackBar },
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style media="screen" lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import "./app_css/global.scss";
/* *{
  font-family: "Gotham", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}
*/
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #bdbdbd;
}

::-webkit-scrollbar-thumb {
  background-color: #2f80ed;
  border-radius: 20px;
}
::-webkit-scrollbar:hover {
  width: 8px;
  height: 10px;
  z-index: 5;
}

::-webkit-scrollbar-track:hover {
  background: #bdbdbd;
  z-index: 6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

button {
  text-transform: capitalize !important;
}
</style>
<style lang="sass">
@import "./app_css/variables.sass"
</style>
