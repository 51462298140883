export default {
    namespaced: true,

    state: {
        reasonModal: {
            show: false,
            ID: null,
            text: "",
            emit: "",
        }
    },

    getters: {
        getReasonModal: (state) => state.reasonModal,
    },

    mutations: {
        TOGGLE_MODAL(state, obj) {
            console.log(obj, 'reason modal');
            if (obj.show) {
                state.reasonModal.ID = obj.ID ? obj.ID : null;
                state.reasonModal.emit = obj.emit ? obj.emit : '';
                state.reasonModal.text = obj.text ? obj.text : '';
            }
            state.reasonModal.show = obj.show;
        }
    },

    actions: {
        toggleModal({ commit }, obj) {
            commit("TOGGLE_MODAL", obj)
        }
    }
}