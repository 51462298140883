export default {
  namespaced: true,
  state: {
    page: 1,
    collection_status: "Overdue",
    selected_tab: 0,

    executive_type: "",
    executive_name: "",
    product_type: "",

    date_menu: false,
    daterange: [],
    display_daterange: null,
    fd_display_date: null,
    follow_up_date: null,
    call_tag: null,
    date_bucket_id: null,
    overdue_days: null,
    today: new Date().toISOString().substr(0, 10),

    current_city: "",
    case_tag: "",
  },

  mutations: {
    SET_FILTER(
      state,
      {
        page,
        follow_up_date,
        product_type,
        call_tag,
        executive_name,
        executive_type,
        date_bucket_id,
        collection_status,
        selected_tab,
        display_daterange,
        daterange,
        date_menu,
        fd_display_date,
        overdue_days,
        current_city,
        case_tag,
      }
    ) {
      state.page = page;
      state.collection_status = collection_status;
      state.selected_tab = selected_tab;
      state.display_daterange = display_daterange;
      state.daterange = daterange;
      state.date_menu = date_menu;
      state.fd_display_date = fd_display_date;
      state.follow_up_date = follow_up_date;
      state.call_tag = call_tag;
      state.date_bucket_id = date_bucket_id;
      state.executive_type = executive_type;
      state.executive_name = executive_name;
      state.product_type = product_type;
      state.overdue_days = overdue_days;
      state.current_city = current_city;
      state.case_tag = case_tag;
    },
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
  },
};
