export default {
    namespaced: true,
    state: {
      page: 1,
      lead_status: "Pending",
      selected_tab: 0,
    //   display_daterange: "",
    //   date_range: [],
    //   initaited_by: "",
    //   city: "",
    //   executive_type: "",
    //   executive_name: "",
    //   product_type: "",
    },
  
    mutations: {
      SET_FILTER(
        state,
        { page, lead_status, selected_tab }
      ) {
        state.page = page;
        state.lead_status = lead_status;
        state.selected_tab = selected_tab;
      },
    },
    actions: {
      setFilters({ commit }, items) {
        commit("SET_FILTER", items);
      },
    },
    getters: {
      getFilters: (state) => {
        return state;
      },
    },
  };
  