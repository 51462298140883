export default {
  namespaced: true,

  state: {
    show_details_page: false,
    status: "",
    id: null,
    refresh_api_count: 0,
    view_details: {
      loan_id: "",
      mobile: "",
    },
  },

  getters: {
    getLoanHistory: (state) => state,
    getRefreshCount: (state) => state.refresh_api_count,
    getViewDetails: (state) => state.view_details,
  },

  mutations: {
    SHOW_LOAN: (state, obj) => {
      state.show_details_page = obj.show_details_page
        ? obj.show_details_page
        : false;
      state.id = obj.id ? obj.id : null;
      state.status = obj.status;
    },
    SET_STATUS: (state, obj) => {
      state.status = obj.status;
    },
    SET_REFRESH_COUNT: (state) => {
      state.refresh_api_count += 1;
    },
    SET_VIEW_DETAILS: (state, obj) => {
      state.view_details.loan_id = obj.loan_id;
      state.view_details.mobile = obj.mobile;
    },
  },

  actions: {
    setLoanHistory({ commit }, obj) {
      commit("SHOW_LOAN", obj);
    },
    setStatus({ commit }, obj) {
      commit("SET_STATUS", obj);
    },
    setRefreshCount({ commit }) {
      commit("SET_REFRESH_COUNT");
    },
    setViewDetails({ commit }, obj) {
      commit("SET_VIEW_DETAILS", obj);
    },
  },
};
