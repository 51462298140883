export default {
  namespaced: true,
  state: {
    page: 1,
    dealer_page: 1,
    selected_tab: 0,
    display_daterange: "",
    date_range: [],
    initaited_by: "",
    city: "",
    executive_type: "",
    executive_name: "",
    product_type: "",
  },

  mutations: {
    SET_FILTER(
      state,
      { page, dealer_page, selected_tab, display_daterange, date_range }
    ) {
      state.page = page;
      state.dealer_page = dealer_page;
      state.selected_tab = selected_tab;
      state.display_daterange = display_daterange;
      state.date_range = date_range;
    },
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
  },
};
