export default {
    namespaced: true,
    state: {
      page: 1,
      tab_status: "reports",
      selected_tab: 0,
    },
  
    mutations: {
      SET_FILTER(
        state,
        { page, selected_tab, tab_status }
      ) {
        state.page = page;
        state.tab_status = tab_status;
        state.selected_tab = selected_tab;
      },
    },
    actions: {
      setFilters({ commit }, items) {
        commit("SET_FILTER", items);
      },
    },
    getters: {
      getFilters: (state) => {
        return state;
      },
    },
  };
  