export default {
    namespaced: true,
    state: {
      page: 1,
      collection_status: "",
      selected_tab: 0,
      display_daterange: "",
      date_range: [],
      initaited_by: "",
      city: "",
      executive_type: "",
      executive_name: "",
      product_type: "",
    },
  
    mutations: {
      SET_FILTER(
        state,
        { page, collection_status, selected_tab, display_daterange, date_range }
      ) {
        state.page = page;
        state.collection_status = collection_status;
        state.selected_tab = selected_tab;
        state.display_daterange = display_daterange;
        state.date_range = date_range;
      },
    },
    actions: {
      setFilters({ commit }, items) {
        commit("SET_FILTER", items);
      },
    },
    getters: {
      getFilters: (state) => {
        return state;
      },
    },
  };
  