export default {
  namespaced: true,
  state: {
    tabs_item: [
      {
        id: 1,
        title: "Team",
        url: { name: "team" },
        role: ["Admin", "Manager", "Tech-Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 1,
        title: "Dealer",
        url: { name: "dealer" },
        role: ["Admin", "Manager", "Tech-Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 2,
        title: "Loan Schema",
        url: { name: "loan-scheme" },
        role: ["Admin", "Manager", "Tech-Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 3,
        title: "LOS",
        url: { name: "lead-list" },
        role: ["Admin", "Manager", "Tech-Support", "Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 3,
        title: "Agent LOS",
        url: { name: "agent-los" },
        role: ["Admin", "Manager", "Tech-Support", "Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 5,
        title: "Disbursement",
        url: { name: "account-list" },
        role: ["Admin", "Tech-Support"],
        hierarchy_level: ["1"],
      },
      {
        id: 4,
        title: "LMS",
        url: { name: "lms-List" },
        role: ["Admin", "Manager", "Tech-Support", "Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 5,
        title: "Collection",
        url: { name: "collection-list" },
        role: ["Admin", "Manager", "Tech-Support", "Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 7,
        title: "Accounts",
        url: { name: "ledger-panel" },
        role: ["Admin", "Manager", "Tech-Support", "Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 7,
        title: "Payments",
        url: { name: "payments-panel" },
        role: ["Admin", "Tech-Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      {
        id: 7,
        title: "Reports",
        url: { name: "reports-panel" },
        role: ["Admin", "Tech-Support"],
        hierarchy_level: ["1", "2", "3"],
      },
      // {
      //   id: 6,
      //   title: "Notice Management",
      //   url: { name: "notice-management" },
      // },
      // {
      //   id: 7,
      //   title: "Session History",
      //   url: { name: "session_history" },
      // },
    ],
  },
  getters: {
    getDrawerTabs: (state) => state.tabs_item,
  },
  mutations: {},
  actions: {},
};
