export default {
  namespaced: true,
  state: {
    page: 1,
    disbursment_status: "Disbursement Approval Pending",
    selected_tab: 0,
    display_daterange: null,
    date_range: [],
    initaited_by: "",
    city: "",
    executive_type: "",
    executive_name: "",
    product_type: "",
    from_date: null,
    to_date: null,
    applied_date_range: [],
    applied_display_date: "",
  },

  mutations: {
    SET_FILTER(
      state,
      {
        page,
        disbursment_status,
        selected_tab,
        display_daterange,
        date_range,
        to_date,
        from_date,
        applied_date_range,
        applied_display_date,
      }
    ) {
      state.page = page;
      state.disbursment_status = disbursment_status;
      state.selected_tab = selected_tab;
      state.display_daterange = display_daterange;
      state.date_range = date_range;
      state.applied_date_range = applied_date_range;
      state.applied_display_date = applied_display_date;
      state.to_date = to_date;
      state.from_date = from_date;
    },
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
  },
};
