const apiUrl = {
  // Base URL
  // BASE_URL: "https://0da2-13-234-124-191.in.ngrok.io/api/",

  // Login Page
  GET_OTP: "/user/send_otp/",
  GENERATE_token: "/user/generate_token/",
  VALIDATE_OTP: "/user/validate_otp/",

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //  Master API
  GET_HIERARCHY_LIST: "/master/get_management_hierarchy_list/",
  GET_ROLE_LIST: "/master/get_management_role_list/",
  GET_PRODUCT_TYPE_LIST: "/master/get_product_type_list/",
  GET_LOAN_TYPE_LIST: "/master/get_loan_type_list/",
  GET_FREQUENCY_LIST: "/master/get_charges_frequency_list/",
  GET_TIME_PERIOD_LIST: "/master/get_time_period_list/",
  GET_TENURE_LIST: "/master/get_tenure_list/",
  GET_VIDEO_CALL_TIMING_LIST: "/master/get_video_call_timing_list/",
  GET_VAS_CHARGES_LIST: "/master/get_vas_charges_list/",
  GET_CALL_TAG_LIST: "master/get_call_tags/", // GET
  GET_STATE_LIST: "/master/state_list/",
  GET_DOC_TYPE_LIST: "/master/doc_type_list/",
  GET_PAYMENT_MODE_LIST: "/master/get_payment_mode_list/",
  GET_LOAN_PURPOSE_LIST: "/master/loan_pupose_list/",
  GET_NBFC_LIST: "/master/get_nbfc_list/",
  GET_BRAND_LIST: "master/get_or_create_vehicle_brand/",
  GET_MODEL_LIST: "master/get_or_create_vehicle_model/",
  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //Create or Get vehicle brand
  ADD_VEHICLE_BRAND: "/master/get_or_create_vehicle_brand/",
  ADD_VEHICLE_MODEL: "/master/get_or_create_vehicle_model/",

  // Teams API
  TEAM_LIST_API: "team/get_teammember_list/",
  ADD_TEAM_MEMBER: "/team/add_teammember_info/",
  GET_TEAM_DETAILS: "/team/get_teammember_info/",
  PATCH_TEAM_DETAILS: "/team/edit_teammember_info/",
  DELETE_TEAM_DETAILS: "/team/delete_teammember_info/",
  MAKE_TEAMMEMBER_ACTIVE_INACTIVE: "/team/make_teammember_active_inactive/",
  GET_LOGGED_USER_INFO: "/team/get_logged_in_teammember_info/",
  GET_ACTIVE_EXECUTIVE_LIST: "/team/get_active_teammember_list/", // GET
  GET_DEALER_LIST: "/team/get_dealer_list/",
  GET_SUPER_ADMIN: "/team/get_superadmin_list/",
  GET_DEALER_PRODUCT: "/team/get_dealer_product_permission/",
  GET_TEAM_PERMISSIONS: "/team/get_permission_info/",
  SET_TEAM_PERMISSIONS: "/team/set_permissions/", // POST -- look_up_key: id

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  // Loan Scheme API
  GET_LOAN_SCHEME: "/loanschema/get_loanscheme_list/",
  MAKE_LOAN_SCHEME_MEMBER_ACTIVE_INACTIVE:
    "/loanschema/make_loanscheme_active_inactive/",
  DELETE_LOAN_SCHEME_DETAILS: "/loanschema/delete_loanscheme_info/",
  ADD_INSURANCE_FEE: "/loanschema/add_loan_insurance_fee/",
  ADD_PROCESSING_FEE: "/loanschema/add_loan_processing_fee/",
  ADD_LOANSCHEME_INFO: "/loanschema/add_loanscheme_info/",
  GET_LOAN_SCHEME_DETAILS: "/loanschema/get_loanscheme_info/",
  PATCH_LOAN_SCHEME_DETAILS: "/loanschema/edit_loanscheme_info/",

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  // Borrower  LOS
  GET_BORROWER_COMPLETE_INFO: "/borrower/get_borrower_complete_info/",
  UPDATE_BORROWER_COMPLETE_INFO: "/borrower/update_borrower_complete_info/",
  ADD_BORROWER_COMPLETE_INFO: "/borrower/admin_create_borrower_complete_info/",
  UPLOAD_BORROWER_DOCUMENTS: "/borrower/admin_upload_borrower_documents/", // PUT -- look_up_key: lead_creation
  CHECK_EXISTING_BORROWER: "/borrower/check_borrower_info/", // GET -- params: lead_creation
  GET_EXTRA_INFO: "/borrower/extra_info/", // GET -- params: lead_creation
  GET_CIBIL_LIST: "/borrower/cibil_list/", // GET -- params: lead_creation
  GET_CIBIL_DETAIL: "/borrower/cibil_detail/",
  ADD_BUSINESS_LOAN_BORROWER_BASIC_DETAIL:
    "/bl_borrower/create_bl_borrower_basic_info/",
  UPDATE_BL_BORROWER_BASIC_DETAIL:
    "/bl_borrower/update_bl_borrower_basic_info/",
  ADD_BUSINESS_LOAN_BORROWER_PERSONAL_DETAIL:
    "/bl_borrower/create_or_update_bl_borrower_personal_info/",
  ADD_BUSINESS_LOAN_BORROWER_BUSINESS_DETAIL:
    "/bl_borrower/add_or_update_borrower_business_details/",
  SUBMIT_LOAN_APPLICATION_FORM: "/bl_borrower/submit_loan_application_form/",
  GL_ADD_ASSETS_DETAILS: "/gl_borrower/add_asset_details/",
  GET_UPDATE_BL_BANK_DETAILS:
    "/bl_borrower/get_or_update_business_bank_details/",
  GET_BL_BORROWER_DETAILS: "/admin/get_bl_borrower_details/",
  GET_ASSIGNED_AGENT: "/los_loan/get_assigned_agent/", // GET -- look_up_key: lead_creation, params: loan_status
  REQUEST_KYC: "/los_loan/request_kyc/", // GET -- look_up_key: lead_creation
  REQUEST_BANK_STATEMENT: "/los_loan/request_bank_statement/", // GET -- look_up_key: lead_creation
  GET_BORROWER_FINVU_DATA: "/borrower/get_borrower_finvu_data/", // GET -- look_up_key: lead_creation
  ADD_MANUAL_BANK_DATA: "/los_loan/add_manual_bank_data/", // POST -- look_up_key: lead_creation
  UPDATE_MANUAL_BANK_DATA: "/los_loan/update_bank_data_info/", // PATCH -- look_up_key: bank_id
  PUSH_LEAD_TO_LOS: "/los_loan/push_loan_to_los/", // GET -- look_up_key: lead_creation

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  // FinVu
  GET_FINVU_DATA_LIST: "/borrower/get_borrower_finvu_data/",
  GET_FINVU_JSON_DATA: "/finvu/get_json_data/",

  // Los loan
  UPLOAD_BS_SS: "/los_loan/upload_bank_salary_document_button/", // PUT -- look_up_key: lead_creation
  UPLOAD_AOD_SS: "/los_loan/upload_address_other_document/", //POST --LOOK_UP_KEY
  CREATE_LOAN: "/los_loan/add_or_update_borrower_loan/", // PUT -- look_up_key: lead_creation

  GET_LOS_LOAN_LIST: "/los_loan/get_loan_list/",
  GET_BORROWER_LOAN_INFO: "/los_loan/get_borrower_loan_information/",
  ASSIGN_LOS_EXECUTIVE: "/los_loan/update_executive_assigned_to_loan/", // PUT -- look_up_key: lead_creation
  LOS_CREATE_COMMENT: "/los_loan/create_comment/",
  LOS_GET_COMMENT: "/los_loan/get_borrower_comments/",
  GET_EXISTING_CUSTOMERS_LIST: "/los_loan/existing_customers_list/",
  DUPLICATE_EXISTING_LOAN: "/los_loan/duplicate_existing_loan/", // GET -- look_up_key: lead_creation
  GUARANTOR_DETAILS:"/bl_borrower/guarantor_details/",
  GET_APPLICATION_LOAN_DATA: "/los_loan/get_application_loan_data/", //GET -- look_up_key: lead_creation

  // stepper
  GET_STEPS: "/los_loan_button/get_internal_loan_status/", // GET -- params: lead_creation
  UPDATE_LOS_LOAN_STATUS: "/los_loan_button/update_loan_status/", // look_up_key: lead_creation
  // internal score
  GET_INTERNAL_SCORE: "/los_loan/get_internal_score/",
  // document verification
  GET_BORROWER_SUBMITTED_DOCUMENT: "/los_loan/get_borrower_submitted_document/",
  MAKE_DOCUMENT_VERIFIED: "/los_loan/document_is_verified_button/",
  MAKE_REQUEST_AGAIN: "/los_loan/document_requested_again_button/",
  GET_APPROVAL_FORM_DETAILS: "/los_loan/get_loan_approval_form/",
  POST_APPROVAL_FORM_DETAILS: "/los_loan/create_loan_approval_form/",
  UPLOAD_GENERAL_DOCUMENTS: "/los_loan/upload_general_document_button/", // PUT -- look_up_key: lead_creation
  UPLOAD_ADDRESS_AND_OTHER_DOC: "/los_loan/upload_address_other_document/", // PUT -- look_up_key: lead_creation
  DELETE_ADDRESS_AND_OTHER_DOC:
    "/los_loan/delete_address_other_document_button/", // PUT -- look_up_key: lead_creation
  UPLOAD_BANK_DOCUMENTS: "/los_loan/upload_bank_salary_document_button/", // PUT -- look_up_key: lead_creation
  GET_UPDATE_DOCUMENTS_INFORMATION:
    "/los_loan/get_or_update_documents_information/",
  // cam
  GET_CAM_DETAILS: "/los_loan_cam/get_cam_detail/",
  GET_CAM_OFFER_LIST: "/los_loan_cam/get_cam_offer_list/", // params: lead_creation
  GENERATE_CAM_OFFER_LIST: "/los_loan_cam/create_or_update_cam_data/", // look_up_key: lead_creation
  SEND_OFFERS_TO_CUSTOMER: "/los_loan_cam/send_offer_to_customer/", // params: lead_creation
  SELECT_OFFER: "/los_loan_cam/admin_update_offer_status/",
  // disbursal details
  GET_DISBURSAL_DETAILS: "/los_loan_charges/get_disbursal_detail/", // GET -- look_up_key: lead_creation
  ADD_VAS_CHARGES: "/los_loan_charges/add_vas_charge/", //POST -- LOOK_UP_KEY  lead ceation
  // video verification
  GET_VIDEO_CALL_SCHEDULE: "/los_loan_video/get_video_call_schedule_list/", // GET -- params: lead_creation
  GET_LAST_VIDEO_CALL_DETAILS: "/los_loan_video/get_last_video_call_detail/", //GET -- look_up_key: lead_creation
  GET_VIDEO_CALL_VERIFICATION_DETAILS:
    "/los_loan_video/get_video_call_result_list/", // GET -- look_up_key: lead_creation
  GET_SIGNATURE: "/los_loan_video/get_signature/", // POST -- look_up_key: lead_creation
  ADD_VIDEO_CALL_SCHEDULE: "/los_loan_video/add_video_schedule/", // POST -- look_up_key: lead_creation
  ADD_VIDEO_CALL_VERIFICATION_DATA:
    "/los_loan_video/add_video_call_verification/", // POST -- look_up_key: lead_creation
  UPLOAD_SIGN: "/los_loan_video/upload_signature/", // POST -- look_up_key: lead_creation
  VERIFY_SIGN: "/los_loan_video/verify_signature/", // POST -- look_up_key: lead_creation
  GENERATE_AGREEMENT: "/los_loan_video/generate_agreement_form/", // GET -- look_up_key: lead_creation
  UPLOAD_AGREEMENT: "/los_loan_video/upload_agreement/",
  GET_DIGIO_STATUS: "/los_loan_video/get_digio_status/",
  GENERATE_BL_AGREEMENT: "/bl_borrower/generate_bl_agreement_form/",
  // enach
  GET_ENACH_DETAILS: "/los_loan_enach/get_enach_details/", // GET -- look_up_key: lead_creation
  UPDATE_ENACH_DETAILS: "/los_loan_enach/create_update_enach/", // PUT/PATCH -- look_up_key: lead_creation
  // payment details
  GET_PAYMENT_DETAILS: "/los_loan_payment/get_payment_details/", //GET -- look_up_key: lead_creation
  GET_PAYMENT_BREAKDOWN: "/los_loan_payment/get_payment_task_details/", //GET -- look_up_key: lead_creation
  UPDATE_BORROWER_BANK_DETAILS:
    "/los_loan_payment/update_borrower_bank_details/", // PUT/PATCH -- look_up_key: lead_creation

  // PAYMENT_RESYNC: "/payments/resync_payments/",

  ADD_REFERENCE_CONTACT: "/los_loan_video/add_reference_contacts/",

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //Account
  GET_ACCOUNT_LIST: "/accounts/get_disbursement_list/", // GET
  GET_LEDGER_LIST: "/accounts/get_ledger_list/", // GET
  POST_ACCOUNT_DISBURSEMENT:
    "/accounts/disbursement/create_update_manual_posting/",
  UNDO_ACCOUNT_DISBURSEMENT: "/accounts/approve_reject_undo_disbursement/",
  GET_ACCOUNT_DISBURSEMENT: "/accounts/disbursement/get_posting_detail/",
  GET_DISBURSAL_DETAIL: "/accounts/get_disbursement_detail/",
  AUTO_POST: "/accounts/disbursement/auto_posting/", // POST -- look_u_key: lead_creation

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  // ledger
  GET_BANK_LEDGER_LIST: "/ledgers/get_bank_ledgers_list/",
  GET_COMPANY_LEDGERS: "/ledgers/company_ledgers/", // GET
  GET_CUSTOMER_LEDGERS: "/ledgers/customer_ledgers/", // GET
  GET_ENTITY_LIST: "/ledgers/ledger_entries_list/", // GET -- look_up_key : id
  GET_VOUCHER_DETAILS: "/ledgers/get_voucher_detail/", // GET -- look_up_key : voucher_no
  GET_ALL_CUSTOMER_LIST: "/ledgers/all_customer_ledgers/",
  GET_CHARGE_LIST: "/los_loan/get_charge_list/",
  GET_EMI_LIST: "/los_loan/get_emi_list/",
  GET_BORROWER_PAYMENT_LIST: "/payments/get_borrower_payments/",
  GET_EMI_CHARGE_LIST: "/los_loan/get_emi_charge_list/",
  CREATE_VOUCHER: "/ledgers/create_voucher/",

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //LMS loan
  GET_LMS_LOAN_LIST: "/lms_loan/get_loan_list/",
  GET_LMS_LOAN_INFO: "/lms_loan/get_loan_info/", //GET -- look_up_key: lead_creation
  GET_BORROWER_LOAN_LIST: "/lms_loan/get_borrower_loan_list/",
  GET_LMS_LOAN_AMENDMENT_LIST: "lms_loan/get_amendment_list/",
  GET_DATE_AMENDMENT_LIST: "master/get_date_amendment_list/",
  POST_LMS_LOAN_ACCEPT_EMI: "lms_loan/accept_emi/",
  POST_LMS_LOAN_DATE_AMENDMENT: "lms_loan/date_amendment/",
  ASSIGN_LMS_EXECUTIVE: "/lms_loan/update_executive_to_lms_loan/", // PUT -- look_up_key: lead_creation
  GET_SETTLEMENT_OVERVIEW: "/lms_loan/settlement_overview/",
  GET_LOAN_SCHEDULE: "/lms_loan/loan_schedule_list/", // GET -- look_up_key: lead_creation
  GET_BORROWER_LEDGER_LIST: "/ledgers/get_borrower_ledger/", // GET -- look_up_key: lead_creation
  GET_REAL_LEDGER_ENTRIES: "/ledgers/borrower/get_real_ledger_entries/", // GET -- look_up_key: lead_creation
  GET_VIRTUAL_LEDGER_ENTRIES: "/ledgers/borrower/get_virtual_ledger_entries/", // GET -- look_up_key: lead_creation
  GET_PUT_SETTLEMENT_OVERVIEW_2: "/lms_loan/settlement_phase_two_overview/", // GET -- look_up_key: lead_creation
  GET_LOAN_STATEMENT_LIST: "/loan_statement/get_loan_statement_list/",
  GET_SUIT_FILED_STATUS: "/lms_loan/get_suit_filed_status/",
  UPDATE_SUIT_FILED_STATUS: "/lms_loan/update_suit_filed_status/",
  GENERATE_LOAN_STATEMENT: `/loan_statement/generate_loan_statements/`,
  GET_STATES_LIST: "/master/get_state_list/",
  GET_BRANCH_DETAILS: "/master/get_branch_by_ifsc/",
  FINVU_GET_CONSENT_LIST :"/finvu/get_consent_list/",
  FINVU_BALANCE_HISTORY :"/finvu/balance_history/",
  FINVU_FETCH_BALANCE: "/finvu/fetch_balance/",
  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //Collection of EMI
  GET_COLLECTION_LIST: "/collection_of_emi/get_collection_emi_list/", // GET
  GET_CONTACT_LIST: "/collection_of_emi/get_contact_list/",
  ADD_CONTACT: "/collection_of_emi/add_contact/",
  ADD_REMARK: "/collection_of_emi/add_update_contact_remark/",
  GET_REFERENCE_CONTACT: "/collection_of_emi/get_reference_contact_list/",
  MARK_ALL_CONTACTS_FAVORITE: "/collection_of_emi/mark_as_fav/",
  REF_CONTACT_FAVORITE: "/collection_of_emi/reference_mark_as_fav/",
  ADD_REF_REMARK: "/collection_of_emi/reference_update_remark/",
  ADD_FOLLOW_UP_DATE: "/collection_of_emi/add_update_follow_up/",
  ADD_UPDATE_CALL_TAG: "/collection_of_emi/add_update_call_tags/", // PUT -- look_u_key: loan_id
  ADD_MANUAL_PAYMENT: "/collection_of_emi/manual_payment/", // POST -- look_u_key: lead_creation
  REQUEST_CHARGE: "/collection_of_emi/send_charge_request/", // POST -- look_u_key: lead_creation
  GET_CHARGES_HISTORY_LIST: "/collection_of_emi/get_charge_list/", // POST -- look_u_key: lead_creation
  ASSIGN_COLLECTION_EXECUTIVE: "/collection_of_emi/assign_executive/", // POST -- look_u_key: lead_creation
  CREATE_COMMENT: "/collection_of_emi/create_comment/",
  GET_COMMENT: "/collection_of_emi/get_borrower_comments/", //get --look_up_key: lead_creation
  SEND_PAYMENT_LINK: "/collection_of_emi/send_payment_link/", //post --look_up_key: lead_creation
  // GET_COURT_CASE_TAG_LIST: "/collection_of_emi/get_court_case_tag_list/", //get
  ASSIGN_COURT_CASE_TAG: "/collection_of_emi/add_update_case_tag/", //post --look_up_key: lead_creation

  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //Payments
  GET_PAYMENT_LIST: "/payments/get_payment_list/", // GET
  GET_PAYMENT_TYPE_LIST: "/payments/payment_type_list/",
  PAYMENT_RESYNC: "/payments/resync_payments/",
  APPROVE_MANUAL_PAYMENT: "/payments/approve_manual_payment/", // POST -- look_u_key: manual_payment_id
  REJECT_MANUAL_PAYMENT: "/payments/reject_manual_payment/", // POST -- look_u_key: manual_payment_id
  /* ****  ****  **** **** **** **** **** **** **** ****   */

  //Notice Management
  NOTICE_LIST_API: "/notice/get_notice_list/",
  DELETE_NOTICE_DETAILS: "/notice/delete_notice/",
  GET_NOTICE_DETAILS: "/notice/get_notice/",
  PATCH_NOTICE_DETAILS: "/notice/update_notice/",
  POST_NOTICE_DETAILS: "/notice/create_notice/",

  //noc
  GET_NOC_TEMPLATE: "/noc/get_noc_template_pdf/",
  UPLOAD_SIGNED_NOC: "/noc/upload_signed_noc/",

  GET_NOC_STATUS: "/noc/get_noc_status/",
  GET_SIGNED_NOC: "/noc/get_signed_noc/",

  CREATE_SETTLEMENT: "/lms_loan/create_settlement_write_off/",
  GET_SETTLEMENT_WRITE_OFF: "/lms_loan/get_settlement_write_off/",
  UNDO_SETTLEMENT: "/lms_loan/undo_settlement/",
  SETTLEMENT_MOVE_TO_COMPLETE: "/lms_loan/settlement_move_to_complete/",
  MOVE_TO_SETTLEMENT: "/lms_loan/move_to_settlement/",
  SETTLEMENT_APPROVE: "/lms_loan/settlement_approve/",

  BL_UPLOAD_KYC: "/los_loan/add_borrower_identity/",
  BL_UPLOAD_DOCUMENTS: "/los_loan/add_or_get_borrower_document/",
  BL_DELETE_DOCUMENTS: "/los_loan/delete_business_document/",
  BL_VERIFY_DOC: "/los_loan/get_or_update_documents_information/",

  ADD_DEALER: "/team/add_dealer/",
  GET_ADD_DEALER_DOC: "/team/get_or_create_dealer_doc/",
  GET_DEALER_DETAILS: "/team/get_dealer_details/",

  ADD_UPDATE_VL_ASSET_DETAILS:
    "/vl_borrower/add_or_update_vehicle_asset_details/",
  GET_VL_BORROWER_DETAILS: "/admin/get_vl_borrower_details/",

  // Reports Panel
  GET_REPORTS_LIST: "/reports/get_reports_list/",
  GET_REPORTS_TYPE_LIST: "/reports/get_reports_type_list/",
  GET_REPORT_FIELDS: "/reports/reports_fields/",
  CREATE_REPORT_TEMPLATE: "/reports/create_report_template/",
  GENERATE_REPORT: "/reports/create_report/",
  GET_STATUS_LIST: "/reports/get_report_stage_filters_list/",
  DELETE_REPORTS_TEMPLATE: "/reports/get_update_delete_report_template/",
  UPDATE_REPORTS_TEMPLATE: "/reports/get_update_delete_report_template/",
  GET_REPORTS_TEMPLATE: "/reports/get_update_delete_report_template/",

  // Agent LOS
  DEALER_CREATE_LEAD :"/dealer/create_lead/",
  DEALER_LIST_DEALER_LEADS :"/dealer/list_dealer_leads/",
  DEALER_CHANGE_LEAD_STATUS: "/dealer/change_lead_status/",
  DEALER_GET_LEAD_DETAILS: "/dealer/get_lead_details/",
  BORROWER_SEND_VERIFICATION_MAIL: "/borrower/send_verification_mail/",
  BORROWER_GET_VERIFICATION_DETAILS:"borrower/get_verification_details/", // GET -- lookup_key = verification_code,
};

export default apiUrl;
