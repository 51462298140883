export default {
  namespaced: true,
  state: {
    page: 1,
    lead_status: "Applied Loan",
    selected_tab: 0,
    display_daterange: "",
    date_range: [],
    scheme: "",
    city: "",
    selected_state: "",
    executive_type: "",
    executive_name: "",
    overdue_days: "",
    due_installments: "",
    product_type: "",
    loan_status: "",
  },

  mutations: {
    SET_FILTER(
      state,
      {
        page,
        lead_status,
        selected_tab,
        display_daterange,
        date_range,
        product_type,
        scheme,
        selected_state,
        city,
      }
    ) {
      state.page = page;
      state.lead_status = lead_status;
      state.selected_tab = selected_tab;
      state.display_daterange = display_daterange;
      state.date_range = date_range;
      state.product_type = product_type;
      state.city = city;
      state.selected_state = selected_state;
      state.scheme = scheme;
    },
    SET_LOAN_STATUS(state, value){
      state.loan_status = value;
    }
  },
  actions: {
    setFilters({ commit }, items) {
      commit("SET_FILTER", items);
    },
    setLoanStatus({commit}, value){
      commit("SET_LOAN_STATUS", value)
    }
  },
  getters: {
    getFilters: (state) => {
      return state;
    },
    getLoanStatus: (state) => {
      return state.loan_status;
    }
  },
};
